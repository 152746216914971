var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-relation-many-to-many","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Conciliações Automáticas")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"id":"conciliacoes-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-alert',{staticClass:"mb-0 py-1 px-1",attrs:{"dense":"","outlined":"","type":"info","color":"blue"}},[_vm._v(" Os processos de conciliações abaixo foram criados pelo sistema após importações de BDGD ou AIS e sempre serão processados durante os finais de semana. ")]),_c('br'),(_vm.conciliacoesAguardando)?_c('v-alert',{staticClass:"mb-0 py-1 px-1",attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" Caso queira cancelar os processos, remova-os clicando nos ícones de exclusão ou "),_c('a',{staticClass:"link",on:{"click":_vm.openDialogDeleteAll}},[_vm._v("clique aqui")]),_vm._v(" para remover todos que estão aguardando processamento. ")]):_vm._e(),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhum processo de conciliação automática disponível"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.mes_dados",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.mes_dados))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more-conciliacao","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.canDeleteItem(item))?_c('v-list-item',{on:{"click":function($event){return _vm.openDialogDelete(item)}}},[_c('v-list-item-icon',{staticClass:"mx-0 my-0 py-4"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1),_c('v-list-item-title',[_vm._v(" Deletar ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogCreationLog(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-table-search ")]),_vm._v(" Log ")],1)],1)],1)],1)]}}],null,true)})],1),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}}),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialogDelete=$event},"submitTriggered":function($event){return _vm.deletarItem(_vm.dialogDeleteData.id)}}},[_c('template',{slot:"title"},[_vm._v("Deletar Conciliação #"+_vm._s(_vm.dialogDeleteData.id))]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja deletar a conciliação "),_c('strong',[_vm._v("#"+_vm._s(_vm.dialogDeleteData.id))]),_vm._v(", de TUC "),_c('strong',[_vm._v(_vm._s(_vm.dialogDeleteData.tuc))]),_vm._v("? ")])],2),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDeleteAll},on:{"update:dialog":function($event){_vm.dialogDeleteAll=$event},"submitTriggered":function($event){return _vm.deletarAllItens()}}},[_c('template',{slot:"title"},[_vm._v("Deletar Conciliações")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja deletar todas as conciliações aguardando processamento? ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }