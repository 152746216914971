<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-relation-many-to-many"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Conciliações Automáticas</div>
      </template>

      <v-text-field
        id="conciliacoes-historico-search"
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />

      <v-divider class="mt-10" />

      <v-alert
        dense
        outlined
        type="info"
        color="blue"
        class="mb-0 py-1 px-1"
      >
        Os processos de conciliações abaixo foram criados pelo sistema após
        importações de BDGD ou AIS e sempre serão processados durante os finais
        de semana.
      </v-alert>

      <br />

      <v-alert
        dense
        outlined
        type="error"
        class="mb-0 py-1 px-1"
        v-if="conciliacoesAguardando"
      >
        Caso queira cancelar os processos, remova-os clicando nos ícones de
        exclusão ou
        <a
          class="link"
          @click="openDialogDeleteAll"
          >clique aqui</a
        >
        para remover todos que estão aguardando processamento.
      </v-alert>

      <v-divider class="mt-10" />

      <v-data-table
        :headers="headers"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhum processo de conciliação automática disponível"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | addLeadingZero }}
        </template>
        <template v-slot:[`item.mes_dados`]="{ item }">
          {{ item.mes_dados | formatToMonth }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <general-status :status="item.status" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more-conciliacao"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="canDeleteItem(item)"
                @click="openDialogDelete(item)"
              >
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Deletar </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openDialogCreationLog(item)">
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </base-material-card>

    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />

    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title"
        >Deletar Conciliação #{{ dialogDeleteData.id }}</template
      >
      <template slot="body">
        Tem certeza que deseja deletar a conciliação
        <strong>#{{ dialogDeleteData.id }}</strong
        >, de TUC <strong>{{ dialogDeleteData.tuc }}</strong
        >?
      </template>
    </dialog-delete>

    <dialog-delete
      :dialog.sync="dialogDeleteAll"
      @submitTriggered="deletarAllItens()"
    >
      <template slot="title">Deletar Conciliações</template>
      <template slot="body">
        Tem certeza que deseja deletar todas as conciliações aguardando
        processamento?
      </template>
    </dialog-delete>
  </v-container>
</template>

<script>
import ConciliacoesAutomaticasBdgdAisService from '@/services/ConciliacoesAutomaticasBdgdAisService';

export default {
  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    DialogDelete: () => import('@/components/general/DialogDelete'),
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue')
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    search: '',
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Mês dos dados',
        value: 'mes_dados'
      },
      {
        text: 'Versão da BDGD',
        value: 'bdgd_versao'
      },
      {
        text: 'TUC',
        value: 'tuc'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ],
    dialogCreationLog: false,
    dialogDelete: false,
    dialogDeleteAll: false,
    dialogDeleteData: {
      id: null,
      tuc: null
    },
    dialogCreationLogData: {
      username: null,
      created: null
    }
  }),

  computed: {
    conciliacoesAguardando() {
      return this.items.filter((item) => item.status === 'AGUARDANDO').length;
    }
  },

  methods: {
    canDeleteItem(item) {
      return item.status === 'AGUARDANDO';
    },
    openDialogCreationLog(item) {
      this.dialogCreationLog = true;
      this.dialogCreationLogData = {
        username: item.criada_por,
        created: item.criada_em
      };
    },
    openDialogDelete(item) {
      this.dialogDelete = true;
      this.dialogDeleteData = {
        id: item.id,
        tuc: item.tuc
      };
    },
    deletarItem(id) {
      ConciliacoesAutomaticasBdgdAisService.deletar(id)
        .then(() => {
          this.$toast.success('Conciliação removida com sucesso.', '', {
            position: 'topRight'
          });
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar a conciliação.', '', {
            position: 'topRight'
          });
          console.error(error);
        });

      this.dialogDelete = false;
    },
    openDialogDeleteAll() {
      this.dialogDeleteAll = true;
    },
    deletarAllItens() {
      ConciliacoesAutomaticasBdgdAisService.deletarPorStatus('AGUARDANDO')
        .then(() => {
          this.$toast.success('Conciliações removidas com sucesso.', '', {
            position: 'topRight'
          });
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar as conciliações.', '', {
            position: 'topRight'
          });
          console.error(error);
        });

      this.dialogDeleteAll = false;
    }
  }
};
</script>

<style scoped>
.link {
  text-decoration: underline;
}
</style>
